import { MA_APP_IDS } from '@wix/members-area-app-definitions';
import { EditorSDK, RouterData, RouterRef } from '@wix/platform-editor-sdk';
import uuid from 'uuid';
import * as routersWrapper from '../wrappers/routers';
import { MembersPage, RouterPatterns } from '../../types/EditorAppModule';
import { shouldDoSyncRouterUpdate } from '../../utils/experiments';
import { log } from '../../utils/monitoring';

function createPageSEOData(pageTitle: string, isPrivate: boolean, noIndex = false) {
  return {
    title: isPrivate ? pageTitle : '{userName} | ' + pageTitle,
    description: '',
    keywords: '',
    noIndex: (isPrivate || noIndex).toString(),
  };
}

function isNoIndexPage(page: MembersPage) {
  const pagesToNoIndexByDefault = [MA_APP_IDS.ABOUT.pageId];
  return pagesToNoIndexByDefault.includes(page?.routerConfig?.appData?.appPageId!);
}

async function getMembersAreaRouters(editorSDK: EditorSDK) {
  const routers = await routersWrapper.getAll(editorSDK);

  if (routers.length === 0) {
    throw new Error('Could not find both routers in #getMembersAreaRouters');
  }

  const publicRouter = routers.find((r) => r.config.type === 'public');

  if (!publicRouter) {
    throw new Error('Could not find the public router in #getMembersAreaRouters');
  }

  const privateRouter = routers.find((r) => r.config.type === 'private');

  if (!privateRouter) {
    throw new Error('Could not find the private router in #getMembersAreaRouters');
  }

  return { publicRouter, privateRouter };
}

function connectPagesToRouterRef({
  editorSDK,
  pages,
  pagesUids,
  routerRef,
}: {
  editorSDK: EditorSDK;
  pages: MembersPage[];
  pagesUids: string[];
  routerRef: RouterRef;
}) {
  const promises = pages.map((page, i) =>
    routersWrapper.connectPageToRouter({
      editorSDK,
      // TODO: possible issue - pageRef might be undefined
      pageRef: page.pageData.pageRef!,
      pageRoles: [pagesUids[i]],
      routerRef,
    }),
  );
  return Promise.all(promises);
}

function createPatternsAndConfigs({ pages, pagesUids }: { pages: MembersPage[]; pagesUids: string[] }) {
  return pages.reduce((acc, page, i) => {
    const { title, isPrivate, pageUriSEO } = page.pageData;
    const pageLink = page.urlOverride || pageUriSEO;
    const pattern = isPrivate ? '/' + pageLink : '/{userName}/' + pageLink;
    const { appData, socialHome } = page.routerConfig || {};
    const seoData = createPageSEOData(title, isPrivate, isNoIndexPage(page));

    // @ts-ignore TODO: Probable issue when routerConfig is undefined.
    acc[pattern] = { socialHome, appData, page: pagesUids[i], seoData, title, pageId: page.routerConfig.pageId };
    return acc;
  }, {});
}

async function connectPagesToRouter({
  editorSDK,
  pages,
  router,
}: {
  editorSDK: EditorSDK;
  router: RouterData;
  pages: MembersPage[];
}) {
  const doSyncRouterUpdate = await shouldDoSyncRouterUpdate();
  const pagesUids = new Array(pages.length).fill(null).map(() => uuid.v4());
  const routerRef = await routersWrapper.getRouterRefByPrefix({ editorSDK, prefix: router.prefix });
  const createdRouterPatternsConfig = createPatternsAndConfigs({ pages, pagesUids });
  const oldRouterPatternsConfig = router.config.patterns || {};
  const newRouterPatternsConfig = { ...oldRouterPatternsConfig, ...createdRouterPatternsConfig };
  const newRouterConfig = { ...router.config, patterns: newRouterPatternsConfig };

  if (!doSyncRouterUpdate) {
    await Promise.all([
      connectPagesToRouterRef({ editorSDK, pages, pagesUids, routerRef }),
      routersWrapper.updateRouterConfig({ editorSDK, config: newRouterConfig, routerRef }),
    ]);
  } else {
    await connectPagesToRouterRef({ editorSDK, pages, pagesUids, routerRef });
    await routersWrapper.updateRouterConfig({ editorSDK, config: newRouterConfig, routerRef });
  }

  // confirm routers update with pages, investigation of pages not being added to "pages" entity
  try {
    const newRouters = await routersWrapper.getAll(editorSDK);
    const updatedRouter = newRouters.find((r) => r.id === router.id) as RouterData;
    const updatedRouterPatternsCount = Object.keys(updatedRouter.config.patterns).length;
    if (updatedRouterPatternsCount !== updatedRouter.pages.length) {
      log('Router config got out of sync', { extra: { updatedRouter } });
    }
  } catch (e) {
    log('An error occured when confirming router configuration', { extra: { e } });
  }
}

const getPatternByPageRole = ({
  routers,
  pageRole,
}: {
  routers: { publicRouter: RouterData; privateRouter: RouterData };
  pageRole: string;
}) => {
  const { privateRouter, publicRouter } = routers;
  const privatePatterns = privateRouter.config.patterns || {};
  const publicPatterns = publicRouter.config.patterns || {};

  // @ts-ignore TODO: Fix router data type in platform editor sdk types
  const privatePatternEntry = Object.entries(privatePatterns).find((p) => p[1].page === pageRole);
  // @ts-ignore
  const publicPatternEntry = Object.entries(publicPatterns).find((p) => p[1].page === pageRole);

  if (privatePatternEntry) {
    return { patternKey: privatePatternEntry[0], router: privateRouter };
  } else if (publicPatternEntry) {
    return { patternKey: publicPatternEntry[0], router: publicRouter };
  } else {
    return {}; // No pattern can be found if the page is not in Members Area
  }
};

const deleteRouterPatternByPatternKey = async ({
  editorSDK,
  router,
  patternKey,
}: {
  editorSDK: EditorSDK;
  router: RouterData;
  patternKey: string;
}) => {
  const routerRef = await routersWrapper.getRouterRefByPrefix({ editorSDK, prefix: router.prefix });
  // TODO: Possible issue due to dynamic delete
  // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
  delete router.config.patterns[patternKey];
  return routersWrapper.updateRouterConfig({ editorSDK, routerRef, config: router.config });
};

function getRouterPatternDataByPageId(router: RouterData, pageId: string) {
  if (router?.config?.patterns) {
    return Object.values(router.config.patterns as unknown as RouterPatterns).find(
      (pattern) => pattern.appData.appPageId === pageId,
    );
  }
}

export {
  getMembersAreaRouters,
  connectPagesToRouter,
  getPatternByPageRole,
  deleteRouterPatternByPatternKey,
  getRouterPatternDataByPageId,
};
